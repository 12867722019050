
/* 
    The event bus / publish-subscribe pattern, despite the bad press it sometimes gets,
    is still an excellent way of getting unrelated sections of your application to talk to each other.
    But wait! Before you go waste a few more precious KBs on another library, why not try Vue’s powerful built-in event bus?

    More details: https://alligator.io/vuejs/global-event-bus/
*/

import Vue from "vue";

export default new Vue();
